/* * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-style: normal;
    margin: 0;
    padding: 0
} */

html {
    scroll-behavior: smooth;
}

body {
    font-size: 100%;
    height: 100%;
}

input,
input[type=button],
input[type=submit],
input[type=text],
select,
textarea {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

input:focus {
    outline: 0;
}

.anime {
    -webkit-transition: all .6s ease-in-out;
    -moz-transition: all .6s ease-in-out;
    -ms-transition: all .6s ease-in-out;
    -o-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;
}

.hidden {
    display: none !important;
}

div.wrapper {
    align-items: center;
    background-image: url('images/wallpaper-ensino.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 100vh;
    justify-content: center;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    width: 100vw;
    z-index: 1;
}

div.wrapper::before {
    background-color: hsla(0, 100%, 98%, .94);
    content: "";
    height: 100vh;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    width: 100vw;
    z-index: 2;
}

.degustacao {
    position: relative;
    max-width: 400px;
    width: 100vw;
    z-index: 3;
}

.degustacao>header {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;
}

.degustacao>header p {
    align-items: center;
    color: hsla(0, 0%, 30%, 1);
    display: flex;
    font-size: .9rem;
    flex-direction: column;
    justify-content: center;
    letter-spacing: .06rem;
    margin-top: .4rem;
    text-align: center;
}

.degustacao>header p strong {
    font-size: 1rem;
}

.frm-degustacao,
.frm-pin {
    display: flex;
}

.frm-degustacao .inputs,
.frm-pin .inputs {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
}

.frm-degustacao .inputs input,
.frm-pin .inputs input {
    background-color: hsla(0, 0%, 90%, 1);
    border: 1px solid hsla(0, 0%, 80%, 1);
    border-radius: .2rem;
    color: hsla(0, 0%, 30%, 1);
    font-size: .9rem;
    padding: .8rem 1rem;
}

.frm-degustacao .inputs input[type="tel"] {
    display: flex;
    margin-bottom: 1rem;
}

.frm-degustacao input[type="submit"],
.frm-pin input[type="submit"] {
    background-color: #00C1B7;
    border: none;
    border-radius: .2rem;
    -webkit-box-shadow: inset 0 0 14px 0 #037E77;
    box-shadow: inset 0 0 14px 0 #037E77;
    color: hsla(0, 100%, 100%, 1);
    cursor: pointer;
    font-weight: bold;
    letter-spacing: .02rem;
    margin-left: 1rem;
    padding: 1rem 2rem;
    text-transform: uppercase;
}

.frm-degustacao input[type="submit"]:hover,
.frm-pin input[type="submit"]:hover {
    -webkit-box-shadow: inset 0 0 40px 0 #037E77;
    box-shadow: inset 0 0 40px 0 #037E77;
}

@media screen and (max-width: 400px) {
    .degustacao {
        max-width: 100%;
        padding: 0 1.6rem;
    }

    .frm-degustacao,
    .frm-pin {
        flex-direction: column;
    }

    .frm-degustacao input[type="submit"],
    .frm-pin input[type="submit"] {
        margin-left: 0;
        margin-top: 1.6rem;
    }
}

@media screen and (max-height: 400px) {
    .degustacao>header img {
        display: none;
    }
}

@media screen and (max-height: 300px) {
    .degustacao>header {
        display: none;
    }
}

.div-error {
    background-color: #f8d7da;
    color: #721c24;
    font-size: 0.9rem;
    margin-top: 1rem;
    border: none;
    border-radius: 0.1rem;
    padding: 1.2rem 0.8rem;
}

.div-error p {
    font-weight: bold;
    color: #721c24;
}

.close-error {
    float: right;
    cursor: pointer;
}